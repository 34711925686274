@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');


@font-face {
  font-family: 'Didot';
  font-style: bold;
  src: local('Didot'), local('Didot'),
       url('./fonts/DidotBold.eot') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/DidotBold.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/DidotBold.woff') format('woff'), /* Modern Browsers */
       url('./fonts/DidotBold.ttf') format('truetype'), /* Safari, Android, iOS */
}

body {
  font-family: 'Open Sans';
}


body, html {
  margin: 0;
  font-family: 'lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: #000000;
  overflow-x:hidden;
}

span{
  font-weight: 700;
}

h1{
  font-family: 'Didot', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 3.2em;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1.1em;
}

h2{
  font-family: 'Didot', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 2.4em;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1.1em;
}

h3{
  font-size: 1.8em;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1.4em;
}

h4{
  font-size: 1.1em;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1.4em;
}

p{
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

ul {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7em;
  list-style: circle;
  padding-left: 20px;
}

li{
  padding-bottom: 10px;
}

ul>li>ul>li{
  padding-bottom: 0px;
}

hr{
  margin: 26px 0px 30px 0px;
  border-bottom: 0;
  border-top: 1px solid #f1f1f1;
}

a{
  text-decoration: none;
  color: #581357;
}

.switch-wrapper {
  width: 100%;
  position: absolute;
}

.nomargin {
  margin: 0;
}

.margin48 {
  margin: 48px 0 48px 0;
}

.grey{
  color: #A4A4A4;
}

.red{
  color: #FF5356;
}

.green{
  color: #55BD98;
}

.purple{
  color: #613694;
}

.blue{
  color: #72CFED;
}

.opacity{
  opacity: 0.5;
}

.button{
  background-color: white!important;
  -webkit-box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1)!important;
  -moz-box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1)!important;
  box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1)!important;
  height: auto!important;
  border-radius: 0!important;
  color: black!important;
  border: 1px solid black!important;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif!important;
  font-weight: 700!important;
  font-size: 1em!important;
  background: url("../components/SharedImages/arrow-next.svg") no-repeat 96% 50%;
  padding: 20px 0px 20px 5%!important;
  text-align: left!important;
  justify-content: left!important;
  transition: all 0.2s ease-in-out!important;
  text-transform: initial!important ;
  font-size: 1.1em!important;
  font-weight: 600!important;
  display: inline-table!important;
}

.button:hover {
  background: url("../components/SharedImages/arrow-next.svg") no-repeat 95% 50%!important;
  background-color: #F6F6F6;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.08)!important;
  -moz-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.08)!important;
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.08)!important;
  transition: all 0.2s ease-in-out!important;
}


.profile-pic{
  border-radius: 16px;
  padding: 0;
  -webkit-box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1);
  background-repeat: no-repeat;
  background-size: 220px;
  background-position: bottom right;
  height: 170px;
  width: 100%;
}

.MuiFilledInput-root {
  background-color: rgba(0, 0, 0, 0.04)!important;
}

.MuiFilledInput-underline:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2)!important;
}

.MuiFormLabel-root{
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif!important;
  font-weight: 500!important;
}

.MuiFilledInput-underline:after {
    border-bottom: 2px solid #581357!important;
}

.MuiFormLabel-root.Mui-focused{
  color: #581357!important;
}

.MuiFilledInput-input{
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif!important;
  font-weight: 600!important;
  color: #581357!important;
}

@media only screen and (max-width: 960px) {
  h1{
    font-size: 3.4em;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1.1em;
  }

  h2{
    font-size: 1.8em;
  }

  .nomargin {
    margin-top: 30px;
  }

  .margin48 {
    margin: 0;
  }

  .MuiContainer-root{
    padding-left: 24px!important;
    padding-right: 24px!important;
  }

}

@media only screen and (max-width: 600px) {

  .profile-pic{
    background-size: 230px;
  }

  hr {
    margin: 26px 0px 4px 0px;
  }

  h1{
    font-size: 2.8em;
    line-height: 1.1em;
  }

}