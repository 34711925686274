@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@font-face {
  font-family: 'Didot';
  font-style: bold;
  src: local('Didot'), local('Didot'),
       url(/static/media/DidotBold.c53e8b2c.eot) format('embedded-opentype'), 
       url(/static/media/DidotBold.a1ef8f5d.woff2) format('woff2'), 
       url(/static/media/DidotBold.f4cf1391.woff) format('woff'), 
       url(/static/media/DidotBold.02d8751b.ttf) format('truetype'), /* Safari, Android, iOS */
}

body {
  font-family: 'Open Sans';
}


body, html {
  margin: 0;
  font-family: 'lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: #000000;
  overflow-x:hidden;
}

span{
  font-weight: 700;
}

h1{
  font-family: 'Didot', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 3.2em;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1.1em;
}

h2{
  font-family: 'Didot', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 2.4em;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1.1em;
}

h3{
  font-size: 1.8em;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1.4em;
}

h4{
  font-size: 1.1em;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1.4em;
}

p{
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

ul {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7em;
  list-style: circle;
  padding-left: 20px;
}

li{
  padding-bottom: 10px;
}

ul>li>ul>li{
  padding-bottom: 0px;
}

hr{
  margin: 26px 0px 30px 0px;
  border-bottom: 0;
  border-top: 1px solid #f1f1f1;
}

a{
  text-decoration: none;
  color: #581357;
}

.switch-wrapper {
  width: 100%;
  position: absolute;
}

.nomargin {
  margin: 0;
}

.margin48 {
  margin: 48px 0 48px 0;
}

.grey{
  color: #A4A4A4;
}

.red{
  color: #FF5356;
}

.green{
  color: #55BD98;
}

.purple{
  color: #613694;
}

.blue{
  color: #72CFED;
}

.opacity{
  opacity: 0.5;
}

.button{
  background-color: white!important;
  box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1)!important;
  height: auto!important;
  border-radius: 0!important;
  color: black!important;
  border: 1px solid black!important;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif!important;
  font-weight: 700!important;
  font-size: 1em!important;
  background: url(/static/media/arrow-next.3c4bc66e.svg) no-repeat 96% 50%;
  padding: 20px 0px 20px 5%!important;
  text-align: left!important;
  -webkit-justify-content: left!important;
          justify-content: left!important;
  transition: all 0.2s ease-in-out!important;
  text-transform: none!important ;
  text-transform: initial!important ;
  font-size: 1.1em!important;
  font-weight: 600!important;
  display: inline-table!important;
}

.button:hover {
  background: url(/static/media/arrow-next.3c4bc66e.svg) no-repeat 95% 50%!important;
  background-color: #F6F6F6;
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.08)!important;
  transition: all 0.2s ease-in-out!important;
}


.profile-pic{
  border-radius: 16px;
  padding: 0;
  box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1);
  background-repeat: no-repeat;
  background-size: 220px;
  background-position: bottom right;
  height: 170px;
  width: 100%;
}

.MuiFilledInput-root {
  background-color: rgba(0, 0, 0, 0.04)!important;
}

.MuiFilledInput-underline:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2)!important;
}

.MuiFormLabel-root{
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif!important;
  font-weight: 500!important;
}

.MuiFilledInput-underline:after {
    border-bottom: 2px solid #581357!important;
}

.MuiFormLabel-root.Mui-focused{
  color: #581357!important;
}

.MuiFilledInput-input{
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif!important;
  font-weight: 600!important;
  color: #581357!important;
}

@media only screen and (max-width: 960px) {
  h1{
    font-size: 3.4em;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1.1em;
  }

  h2{
    font-size: 1.8em;
  }

  .nomargin {
    margin-top: 30px;
  }

  .margin48 {
    margin: 0;
  }

  .MuiContainer-root{
    padding-left: 24px!important;
    padding-right: 24px!important;
  }

}

@media only screen and (max-width: 600px) {

  .profile-pic{
    background-size: 230px;
  }

  hr {
    margin: 26px 0px 4px 0px;
  }

  h1{
    font-size: 2.8em;
    line-height: 1.1em;
  }

}
/**************
** HOME PAGE **
***************/

#home-hero{
  margin-top: -84px;
  padding-top: 25em;
  padding-bottom: 1em;
  background: url(/static/media/ahbackground.2b1c2422.jpg) no-repeat right;
  background-size: contain;
  background-color: #000000;
}

#home-hero h3{
  font-family: 'Didot', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 2.1em;
  font-weight: 700;
  margin: 0;
  color: #fff;
  padding: 0;
  line-height: 1.1em;
  padding-top: 100px;
}

#home-hero h3 span{
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 0.6em;
  font-weight: 700;
  margin: 0;
  color: #fff;
  padding: 0;
  line-height: 1.1em;
}

.position{
  border: 1px solid rgb(228, 228, 228);
  padding: 5px 30px 30px 30px;
}

.noreco{
  padding: 5px 30px 5px 30px;
}

.noreco p:first-child{
  margin-bottom: 5px;
}

.noreco p:last-child{
  margin-top: 0;
}

#monparcours{
  padding-left: 80px;
}

#scrolldown{
  position: absolute;
  position: absolute;
  margin-top: -55px;
  margin-left: -15px;
}

#scrolldown img{
  width: 100px;
}

#rivage{
  padding-top: 70px;
  padding-bottom: 20px;
}

#mrestaurants, #german, #nelligan {
  padding-bottom: 10px;
}

#fredericks, #maze, #avenue, #nm {
  padding-bottom: 20px;
}

.image-gallery-bullets .image-gallery-bullet:focus, .image-gallery-bullets .image-gallery-bullet:hover {
  background: black;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.dividers{
  margin-top: 50px;
  margin-bottom: 30px;
}

.imagesinformations{
  margin-top: 40px;
}

.image-gallery-slide-wrapper {
  height: 450px;
  overflow: hidden;
}

.image-gallery-slide .image-gallery-image {
  width: 100%;
  min-height: 450px;
  object-fit: cover;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 50px;
  width: 50px;
}

.image-gallery-icon {
  -webkit-filter: none;
          filter: none;
}

.extrainformations{
  height: 350px;
  overflow: hidden;
  position: relative;
  padding: 50px;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
}

.extrainformations h3{
  font-family: 'Didot', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 2.1em;
  font-weight: 700;
  margin: 0;
  color: #000;
  padding: 0;
  line-height: 1.1em;
  padding: 0 0 18px 0;
}

.extrainformations h3 span{
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 0.6em;
  font-weight: 700;
  margin: 0;
  color: #000;
  padding: 0;
  line-height: 1.1em;
}

.extrainformations::before {
  content: "";
  position: absolute;
  width: 143%;
  height: 187%;
  top: -50%;
  left: -26%;
  z-index: -1;
  background: url(/static/media/ahpattern.3568c04d.svg) repeat 10px;
  background-size: 55px;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
}

.key-sentences{
  background-color: #ffffff;
  border: 1px solid #EBEBEB;
  border-radius: 16px;
  height: 100%;
  min-height: 270px;
}

.key-sentences h2{
  width: 78%;
  line-height: 1.2em;
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#sources p{
  font-size: 16px;
  padding: 0;
  margin: 0;
}

#sources a{
  color: #A4A4A4;
}

span .grey{
  color: grey;
  font-weight: 200;
}


@media (min-width: 768px) {
  .image-gallery-icon:hover {
    color: #000;
  }

}


@media only screen and (max-width: 960px) {

  .logossvg{
    max-width: 100%;
  }

  .position {
    padding: 0px 20px 20px 20px;
  }

  .noreco{
    padding: 0px 20px 0px 20px;
  }

  .extrainformations::before {
    content: "";
    position: absolute;
    width: 300%;
    height: 300%;
    top: -50%;
    left: -70%;
  }

  .aurorehellotitle{
    width: 200px;
  }

  .extrainformations {
    height: auto;
    padding: 40px 30px 20px 30px;
  }

  .extrainformations{
    border-top: none;
    border-bottom: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    border-left: 1px solid #f3f3f3;
  }

  #home-hero{
    background: url(/static/media/ahbackground.2b1c2422.jpg) no-repeat 100% 20%;
    background-size: 90%;
    padding-bottom: 3em;
    padding-top: 25em;
    background-color: #000;
  }

  #home-hero h3 {
    padding-top: 50px;
}

  #sources{
    margin-top: 32px;
  }

  .key-sentences{
    width: 100%;
    display: -webkit-inline-flex;
    display: inline-flex;
    min-height: 0;
  }

  .key-sentences h2{
    padding: 40px;
    width: 100%;
    line-height: 1.2em;
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }

}

@media only screen and (max-width: 640px) {
  #home-hero{
    background: url(/static/media/ahbackground.2b1c2422.jpg) no-repeat 80% 20%;
    background-size: 120%;
    padding-bottom: 3em;
    padding-top: 25em;
    background-color: #000;
  }

}
/***********
** FOOTER **
************/

.footer{
    margin-top: 50px;
    background: #000;
    padding-bottom: 30px;
    text-align: left;
}

#footer-container{
    padding: 60px 0 30px 0;
    margin-bottom: 30px;
    color: white;
}

.footer svg{
    width: 94px;
    margin-bottom: 30px;
}

.footer ul{
    list-style-type: none;
    margin: 0;
    padding: 10px 0 0 0;
    font-weight: 500;
    font-size: 1em;
}

.footer li{
    padding-bottom: 10px;
}

.footer li a:hover{
    opacity: 0.6;
}

.footer .selected:before {
    content: "•";
    font-size: 32px;
    line-height: 0px;
    position: relative;
    left: -8px;
    bottom: -3px;
    height: 0;
    width: 0;
    margin-left: -10px;
}

.footer #copyright{
    font-weight: 500;
    font-size: 0.9em;
    color: #b9b9b9;
    text-align: center;
}

.footer a{
    color: white;
    font-weight: 700;
    margin-bottom: 20px;
}

.footerbutton{
    background-color: black!important;
    box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1)!important;
    height: auto!important;
    border-radius: 0!important;
    color: white!important;
    border: 1px solid white!important;
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
    font-weight: 700!important;
    font-size: 1em!important;
    background: url(/static/media/arrow-next-white.e86bb1dd.svg) no-repeat 96% 50%;
    padding: 20px 0px 20px 5%!important;
    text-align: left!important;
    -webkit-justify-content: left!important;
            justify-content: left!important;
    transition: all 0.2s ease-in-out!important;
    text-transform: none!important ;
    text-transform: initial!important ;
    font-size: 1.1em!important;
    font-weight: 600!important;
    display: inline-table!important;
  }
  
  .footerbutton:hover {
    background: url(/static/media/arrow-next-white.e86bb1dd.svg) no-repeat 95% 50%!important;
    background-color: #F6F6F6;
    transition: all 0.2s ease-in-out!important;
  }

@media only screen and (max-width: 960px) {

    .footer{
        text-align: center;
        padding-bottom: 50px;
    }

    .footer svg {
        padding: 5px 40px 10px 40px;
    }
}
/**************
** 404 PAGE **
***************/

#errorpage-container{
    margin-top: -84px;
    padding-top: 16em;
    padding-bottom: 6em;
    background-size: contain;
}
/***********
** NAVBAR **
************/

.navbar {
    padding: 20px 0 40px 0;
    position: fixed;
    width: 100%;
    z-index: 100;
    transition: all 0.3s ease-in-out;
}

.navbar svg{
    padding-top: 5px;
    width: 60px;
    transition: all 0.3s ease-in-out;
}

.navbar #nav-items{
    text-align: center;
    padding-top: 30px;
}

.navbar #nav-items a{
    text-decoration: none;
    color: #581357;
    font-weight: 600;
    padding: 20px 20px 16px 20px;
    position: relative;
}

.navbar .selected:after {
    content : "";
    position: absolute;
    left    : 50%;
    bottom  : 2px;
    height  : 3px;
    width   : 14px;
    margin-left: -6px;
    border-bottom:3px solid #581357;
    transition: all 0.3s ease-in-out;
}

.navbar.active .selected:after {
    bottom: -4px;
    width: 36px;
    margin-left: -18px;
    transition: all 0.3s ease-in-out;
}

.navbar.active{
    padding: 5px 0 0px 0;
    background: white;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
}

.navbar.active svg{
    height: 50px;
    width: 50px;
    padding-bottom: 8px;
    transition: all 0.3s ease-in-out;
}

.navbar-mobile{
    display: none;
    background-color: #ffffff;
    box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100%;
    height: 54px;
    text-align: center;
}

.navbar-mobile svg{
    width: 40px;
    height: 50px;
    margin-top: 4px;
}

.navbar-mobile-toggle{
    height: 38px;
    width: 38px;
    cursor: pointer;
    position: absolute;
    margin-left: 16px;
    margin-top: 8px;
    background: url(/static/media/ic_menu.e7de600f.svg) no-repeat center center;
    background-size: 24px;
    transition: all 0.2s ease-in-out;
}

.navbar-mobile-toggle.active{
    background: url(/static/media/ic_close.bdb42042.svg) no-repeat center center;
    transition: all 0.2s ease-in-out;
}

.navbar-mobile-menu{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    opacity: 0;
    height: 100%;
    position: fixed;
    text-align: left;
    z-index: 0;
    top: 54px;
    border-top: 1px solid #DBDBDB;
    margin-left: -100%;
    transition: all 0.3s ease-in-out;
}

.navbar-mobile-menu.active{
    margin-left: 0;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.navbar-mobile-menu a{
    display: block;
    text-decoration: none;
    color: #581357;
    font-size: 18px;
    font-weight: 600;
    padding: 20px 20px 24px 10%;
    position: relative;
    transition: all 0.5s ease-in;
}

.navbar-mobile-menu a:after {
    content : "";
    position: absolute;
    left    : 10%;
    bottom  : 4px;
    height  : 1px;
    width   : 80%;
    border-bottom:1px solid #DBDBDB;
    transition: all 0.3s ease-in-out;
}


@media only screen and (max-width: 960px) {

    .navbar{
        display: none;
    }

    .navbar-mobile{
        display: inline;
    }
}
