/***********
** FOOTER **
************/

.footer{
    margin-top: 50px;
    background: #000;
    padding-bottom: 30px;
    text-align: left;
}

#footer-container{
    padding: 60px 0 30px 0;
    margin-bottom: 30px;
    color: white;
}

.footer svg{
    width: 94px;
    margin-bottom: 30px;
}

.footer ul{
    list-style-type: none;
    margin: 0;
    padding: 10px 0 0 0;
    font-weight: 500;
    font-size: 1em;
}

.footer li{
    padding-bottom: 10px;
}

.footer li a:hover{
    opacity: 0.6;
}

.footer .selected:before {
    content: "•";
    font-size: 32px;
    line-height: 0px;
    position: relative;
    left: -8px;
    bottom: -3px;
    height: 0;
    width: 0;
    margin-left: -10px;
}

.footer #copyright{
    font-weight: 500;
    font-size: 0.9em;
    color: #b9b9b9;
    text-align: center;
}

.footer a{
    color: white;
    font-weight: 700;
    margin-bottom: 20px;
}

.footerbutton{
    background-color: black!important;
    -webkit-box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1)!important;
    -moz-box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1)!important;
    box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1)!important;
    height: auto!important;
    border-radius: 0!important;
    color: white!important;
    border: 1px solid white!important;
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
    font-weight: 700!important;
    font-size: 1em!important;
    background: url("../SharedImages/arrow-next-white.svg") no-repeat 96% 50%;
    padding: 20px 0px 20px 5%!important;
    text-align: left!important;
    justify-content: left!important;
    transition: all 0.2s ease-in-out!important;
    text-transform: initial!important ;
    font-size: 1.1em!important;
    font-weight: 600!important;
    display: inline-table!important;
  }
  
  .footerbutton:hover {
    background: url("../SharedImages/arrow-next-white.svg") no-repeat 95% 50%!important;
    background-color: #F6F6F6;
    transition: all 0.2s ease-in-out!important;
  }

@media only screen and (max-width: 960px) {

    .footer{
        text-align: center;
        padding-bottom: 50px;
    }

    .footer svg {
        padding: 5px 40px 10px 40px;
    }
}