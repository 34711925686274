/***********
** NAVBAR **
************/

.navbar {
    padding: 20px 0 40px 0;
    position: fixed;
    width: 100%;
    z-index: 100;
    transition: all 0.3s ease-in-out;
}

.navbar svg{
    padding-top: 5px;
    width: 60px;
    transition: all 0.3s ease-in-out;
}

.navbar #nav-items{
    text-align: center;
    padding-top: 30px;
}

.navbar #nav-items a{
    text-decoration: none;
    color: #581357;
    font-weight: 600;
    padding: 20px 20px 16px 20px;
    position: relative;
}

.navbar .selected:after {
    content : "";
    position: absolute;
    left    : 50%;
    bottom  : 2px;
    height  : 3px;
    width   : 14px;
    margin-left: -6px;
    border-bottom:3px solid #581357;
    transition: all 0.3s ease-in-out;
}

.navbar.active .selected:after {
    bottom: -4px;
    width: 36px;
    margin-left: -18px;
    transition: all 0.3s ease-in-out;
}

.navbar.active{
    padding: 5px 0 0px 0;
    background: white;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
}

.navbar.active svg{
    height: 50px;
    width: 50px;
    padding-bottom: 8px;
    transition: all 0.3s ease-in-out;
}

.navbar-mobile{
    display: none;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100%;
    height: 54px;
    text-align: center;
}

.navbar-mobile svg{
    width: 40px;
    height: 50px;
    margin-top: 4px;
}

.navbar-mobile-toggle{
    height: 38px;
    width: 38px;
    cursor: pointer;
    position: absolute;
    margin-left: 16px;
    margin-top: 8px;
    background: url("./images/ic_menu.svg") no-repeat center center;
    background-size: 24px;
    transition: all 0.2s ease-in-out;
}

.navbar-mobile-toggle.active{
    background: url("./images/ic_close.svg") no-repeat center center;
    transition: all 0.2s ease-in-out;
}

.navbar-mobile-menu{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    opacity: 0;
    height: 100%;
    position: fixed;
    text-align: left;
    z-index: 0;
    top: 54px;
    border-top: 1px solid #DBDBDB;
    margin-left: -100%;
    transition: all 0.3s ease-in-out;
}

.navbar-mobile-menu.active{
    margin-left: 0;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.navbar-mobile-menu a{
    display: block;
    text-decoration: none;
    color: #581357;
    font-size: 18px;
    font-weight: 600;
    padding: 20px 20px 24px 10%;
    position: relative;
    transition: all 0.5s ease-in;
}

.navbar-mobile-menu a:after {
    content : "";
    position: absolute;
    left    : 10%;
    bottom  : 4px;
    height  : 1px;
    width   : 80%;
    border-bottom:1px solid #DBDBDB;
    transition: all 0.3s ease-in-out;
}


@media only screen and (max-width: 960px) {

    .navbar{
        display: none;
    }

    .navbar-mobile{
        display: inline;
    }
}