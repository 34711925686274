@import "~react-image-gallery/styles/css/image-gallery.css";

/**************
** HOME PAGE **
***************/

#home-hero{
  margin-top: -84px;
  padding-top: 25em;
  padding-bottom: 1em;
  background: url("./images/ahbackground.jpg") no-repeat right;
  background-size: contain;
  background-color: #000000;
}

#home-hero h3{
  font-family: 'Didot', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 2.1em;
  font-weight: 700;
  margin: 0;
  color: #fff;
  padding: 0;
  line-height: 1.1em;
  padding-top: 100px;
}

#home-hero h3 span{
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 0.6em;
  font-weight: 700;
  margin: 0;
  color: #fff;
  padding: 0;
  line-height: 1.1em;
}

.position{
  border: 1px solid rgb(228, 228, 228);
  padding: 5px 30px 30px 30px;
}

.noreco{
  padding: 5px 30px 5px 30px;
}

.noreco p:first-child{
  margin-bottom: 5px;
}

.noreco p:last-child{
  margin-top: 0;
}

#monparcours{
  padding-left: 80px;
}

#scrolldown{
  position: absolute;
  position: absolute;
  margin-top: -55px;
  margin-left: -15px;
}

#scrolldown img{
  width: 100px;
}

#rivage{
  padding-top: 70px;
  padding-bottom: 20px;
}

#mrestaurants, #german, #nelligan {
  padding-bottom: 10px;
}

#fredericks, #maze, #avenue, #nm {
  padding-bottom: 20px;
}

.image-gallery-bullets .image-gallery-bullet:focus, .image-gallery-bullets .image-gallery-bullet:hover {
  background: black;
  transform: scale(1.1);
}

.dividers{
  margin-top: 50px;
  margin-bottom: 30px;
}

.imagesinformations{
  margin-top: 40px;
}

.image-gallery-slide-wrapper {
  height: 450px;
  overflow: hidden;
}

.image-gallery-slide .image-gallery-image {
  width: 100%;
  min-height: 450px;
  object-fit: cover;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 50px;
  width: 50px;
}

.image-gallery-icon {
  filter: none;
}

.extrainformations{
  height: 350px;
  overflow: hidden;
  position: relative;
  padding: 50px;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
}

.extrainformations h3{
  font-family: 'Didot', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 2.1em;
  font-weight: 700;
  margin: 0;
  color: #000;
  padding: 0;
  line-height: 1.1em;
  padding: 0 0 18px 0;
}

.extrainformations h3 span{
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 0.6em;
  font-weight: 700;
  margin: 0;
  color: #000;
  padding: 0;
  line-height: 1.1em;
}

.extrainformations::before {
  content: "";
  position: absolute;
  width: 143%;
  height: 187%;
  top: -50%;
  left: -26%;
  z-index: -1;
  background: url("./images/ahpattern.svg") repeat 10px;
  background-size: 55px;
  transform: rotate(40deg);
}

.key-sentences{
  background-color: #ffffff;
  border: 1px solid #EBEBEB;
  border-radius: 16px;
  height: 100%;
  min-height: 270px;
}

.key-sentences h2{
  width: 78%;
  line-height: 1.2em;
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#sources p{
  font-size: 16px;
  padding: 0;
  margin: 0;
}

#sources a{
  color: #A4A4A4;
}

span .grey{
  color: grey;
  font-weight: 200;
}


@media (min-width: 768px) {
  .image-gallery-icon:hover {
    color: #000;
  }

}


@media only screen and (max-width: 960px) {

  .logossvg{
    max-width: 100%;
  }

  .position {
    padding: 0px 20px 20px 20px;
  }

  .noreco{
    padding: 0px 20px 0px 20px;
  }

  .extrainformations::before {
    content: "";
    position: absolute;
    width: 300%;
    height: 300%;
    top: -50%;
    left: -70%;
  }

  .aurorehellotitle{
    width: 200px;
  }

  .extrainformations {
    height: auto;
    padding: 40px 30px 20px 30px;
  }

  .extrainformations{
    border-top: none;
    border-bottom: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    border-left: 1px solid #f3f3f3;
  }

  #home-hero{
    background: url("./images/ahbackground.jpg") no-repeat 100% 20%;
    background-size: 90%;
    padding-bottom: 3em;
    padding-top: 25em;
    background-color: #000;
  }

  #home-hero h3 {
    padding-top: 50px;
}

  #sources{
    margin-top: 32px;
  }

  .key-sentences{
    width: 100%;
    display: inline-flex;
    min-height: 0;
  }

  .key-sentences h2{
    padding: 40px;
    width: 100%;
    line-height: 1.2em;
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

@media only screen and (max-width: 640px) {
  #home-hero{
    background: url("./images/ahbackground.jpg") no-repeat 80% 20%;
    background-size: 120%;
    padding-bottom: 3em;
    padding-top: 25em;
    background-color: #000;
  }

}